/* stylelint-disable */
// Inspired by https://www.joshwcomeau.com/css/custom-css-reset/

// Use a more-intuitive box-sizing model
*,
*:before,
*:after {
  box-sizing: border-box;
}

// Remove default margin
* {
  margin: 0;
}

html,
:root {
  // 1rem = 10px
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;

  // Predictable line-height
  line-height: 1;

  // Improve text rendering
  -webkit-font-smoothing: antialiased;
}

// Inherit fonts for form controls
input,
button,
textarea,
select {
  font: inherit;
}

// Avoid text overflows
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  overflow-wrap: break-word;
}

// Improve line wrapping
p {
  text-wrap: pretty;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

a {
  text-decoration: none;
}

// Create a root stacking context
#root,
#__next {
  isolation: isolate;
}
