@import '/src/styles/utilities.scss';

.devInfo {
  position: fixed;
  bottom: 0;
  z-index: $zindex-popover;
  margin: $space-4;
}

.devInfo__toggle {
  display: inline-flex;
  gap: $space-4;
  align-items: center;
  padding: $space-4;
  margin-top: $space-4;
  font-family: monospace;
  color: var(--color-text-light);
  cursor: pointer;
  background-color: var(--color-gocomics-blue);
  border-radius: $border-radius-5;
}

.devInfo__group {
  display: flex;
  flex-direction: column;
  gap: $space-12;
  padding: $space-12;
}

.devInfo__item {
  font-family: monospace;
  font-size: 1.4rem;
  line-height: 1.5;
  white-space: nowrap;

  ul {
    padding-inline-start: $space-24;
  }
}
