// Browser normalization and reset
@import '/src/styles/reset.scss';

// Base utilities with variables, mixins, and functions
@import '/src/styles/utilities.scss';

// Special inherited tokenized CSS variables
@import '/src/styles/tokens.scss';

// Global styles that are too opinionated to be considered a reset
body {
  // Prevent horizontal scrolling
  width: 100vw;
  max-width: 100%;
}

a {
  @include apply-link-styles('color-gocomics-blue');
}
